<template>
  <div class="place">
    <Icons :icon-name="cardData.icon" class="place__icon" />
    <span class="place__title">{{ cardData.title }}</span>
  </div>
</template>

<script>
import Icons from '@/components/atoms/Icons/index.vue'

export default {
  name: 'comfortableYard',
  components: { Icons },
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.place {
  min-width: fit-content;
  justify-content: space-between;
  max-height: 174px;
  @include flex-column();

  @include mobile {
    min-width: 170px;
    max-height: none;
    height: fit-content;
  }

  &__title {
    max-width: 80%;
    @include font-styles(15px, 20px, 400, $c-gray);

    @include mobile {
      max-width: none;
    }
  }
  &__icon {
    max-width: 90px;
    padding-bottom: 22px;

    @include mobile {
      padding-bottom: 12px;
      max-width: 70px;
    }
  }
}
</style>
