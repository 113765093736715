<template>
  <div class="sixth container">
    <div class="sixth-inner">
      <section class="sixth-left">
        <h2 class="sixth-left__title wow" data-wow-offset="200">
          Удобный двор
        </h2>
        <p class="sixth-left__text--strong wow" data-wow-offset="200">
          Двор является неотъемлемой частью любого жилого комплекса, поэтому его
          развитие и комфорт очень важный пункт для нас, который не останется
          без внимания
        </p>
        <div class="sixth-left__cards-list">
          <Card
            v-for="(card, i) in cards"
            :key="i"
            :card-data="card"
            class="sixth-left__card wow"
            data-wow-offset="100"
          />
        </div>
      </section>
      <div class="sixth-right wow" data-wow-offset="200">
        <img
          src="@/assets/images/sixth/right-img.webp"
          class="sixth-right__img"
          alt="Фото двора ЖК"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/molecules/Cards/ComfortableYard/index.vue'
import { WOW } from 'wowjs'
export default {
  name: 'SixthSection',
  components: { Card },
  data() {
    return {
      cards: [
        {
          title:
            'Доступ в жилой комплекс обеспечивается по магнитному ключу и домофону',
          icon: 'gates',
        },
        {
          title:
            'Для детей создана детская площадка, безопасность которой, мы гарантируем',
          icon: 'kids-area',
        },
        {
          title:
            'Круглосуточные камеры видеонаблюдения, которые записывают все происходящее',
          icon: 'camera',
        },
        {
          title:
            'Cпортивная площадка с многофункциональным полем и велопарковкой',
          icon: 'sport-area',
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
}
</script>

<style lang="scss" scoped>
.sixth {
  width: 100%;
  position: relative;
  height: fit-content;

  &-inner {
    gap: 50px;
    justify-content: space-between;
    @include flex-row();

    @include tablet-portrait {
      gap: 10px;
      flex-direction: column;
    }

    @include mobile-landscape {
      flex-direction: column;
    }
    @include mobile {
      gap: 16px;
    }
  }

  &-left {
    align-items: flex-start;
    justify-content: center;
    max-width: 600px;
    @include flex-column();

    @include tablet-portrait {
      max-width: none;
      order: 2;
    }
    @include mobile-landscape {
      max-width: none;
      width: 100%;
    }
    &__title {
      padding-bottom: 30px;
      animation: manifestation1 0.8s ease-in;
      @include font-styles(48px, 58px, 700, $c-dark, 0, $font-third);

      @include mobile {
        padding-bottom: 14px;
        @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
      }
    }
    &__text--strong {
      animation: manifestation1 0.8s ease-in;
      max-width: 580px;
      padding-bottom: 38px;
      @include font-styles(22px, 30px, 600, $c-dark);

      @include laptop {
        max-width: 480px;
      }

      @include tablet-portrait {
        max-width: 80%;
        padding-bottom: 10px;
      }

      @include mobile {
        max-width: none;
        text-align: left;
        padding-bottom: 14px;
        @include font-styles(16px, 20px, 700, $c-dark);
      }
    }
    &__card {
      max-height: 174px;
      overflow: hidden;

      @include mobile {
        max-height: none;
      }
      &:nth-child(1) {
        animation: manifestation1 0.8s ease-in;
      }
      &:nth-child(2) {
        animation: manifestation1 1s ease-in;
      }
      &:nth-child(3) {
        animation: manifestation1 0.8s ease-in;
      }
      &:nth-child(4) {
        animation: manifestation1 1s ease-in;
      }
    }
    &__cards-list {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);

      @include tablet-portrait {
        gap: 10px;
      }

      @include mobile {
        gap: 15px;
        width: 100vw;
        padding-right: 20px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        @include flex-row();
      }
    }
  }
  &-right {
    @include flex-row();
    animation: manifestation1 0.8s ease-in;
    align-items: flex-end;
    justify-content: center;
    &__img {
      border-radius: 120px 40px 40px 40px;
      height: 590px;
      min-width: 590px;
      object-fit: cover;
      object-position: center;

      @include laptop {
        min-width: 450px;
        height: 450px;
      }
      @include tablet-portrait {
        min-width: auto;
        width: 100%;
        height: 560px;
      }

      @include mobile {
        border-radius: 60px 20px 20px 20px;
        width: 100%;
        height: 340px;
      }
    }
  }
}
</style>
